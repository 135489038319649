.formalReportPage {
  * {
    // direction: rtl;
    // text-align: right;
    word-wrap: normal !important;
  }
  @media print {
    padding: 40px 20px 0 !important;
    background-color: $white !important;
    height: 99% !important;
    display: flex;
    flex-direction: column;
    position: relative;
    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url("../../assets/images/izoneLogo.png") !important;
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center center;
    }
  }

  .reportHeader {
    display: none;
    @media print {
      display: flex;
    }
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .headerTitle {
      color: $blue;
      font-size: 25px;
      margin-bottom: 10px;
    }
    .reportHistory {
      font-family: "Light";
      text-align: right;
      font-size: 16px;
    }
    .headerLogo {
      width: 160px;
      @media print {
        width: 200px;
      }
    }
  }
  .infoNav {
    background-color: $blue;
    padding: 5px 20px;
    display: none;
    @media print {
      display: flex;
    }
    justify-content: space-between;
    margin-bottom: 15px;
    .reportId {
      font-size: 16px;
      position: initial;
      width: fit-content;
    }
    .isVip {
      color: $white;
      * {
        color: $white;
      }
    }
  }
  .ant-checkbox-inner {
    border-radius: 5px;
  }
  .reportInfos {
    padding: 10px 10px 0;
    .reportInfo {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 1rem;
      .label {
        font-family: "Light";
        display: inline-block;
        word-break: keep-all;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        // padding-left: 50px;
        color: $black;
        .blue {
          color: $blue;
        }
      }
      .vprint {
        display: none;
      }
      .value {
        width: -webkit-fill-available;

        &.checkBoxes {
          display: flex;
          justify-content: space-between;
        }
        color: $gray7 !important;

        * {
          color: $gray7 !important;
        }
      }
      .formCheck {
        margin-left: 20px;
        span {
          font-size: 12px;
          color: $gray9 !important;
        }
      }
      textarea {
        border: 1px solid $whiteE !important;
        box-shadow: none !important;
        border-radius: 10px;
        padding: 8px 20px;
        resize: none;
        height: auto;
        min-height: 100px;
        white-space: pre-wrap !important;
        word-wrap: break-word !important;
        @media print {
          border: none !important;
          padding: 0;
          min-height: auto !important;
          background: none !important;
        }
      }
    }
  }
  .tableHeader {
    background-color: $blue;
    text-align: center;
    color: $white;
    padding: 4px;
  }
  .products {
    direction: rtl;
    text-align: right;
    margin-bottom: 20px;

    * {
      direction: rtl;
      text-align: right;
    }
    .ant-table-cell {
      padding: 8px 12px;
      font-size: 12px;
      @media print {
        background: transparent !important;
      }
    }
    @media print {
      .ant-table-row {
        background-color: transparent !important;
      }
      .ant-table {
        background-color: transparent !important;
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid $gray9;
        border-right: 1px solid $gray9 !important;
      }
      .ant-table-thead > tr > th {
        border-bottom: 1px solid $gray9;
        border-right: 1px solid $gray9 !important;
      }
      .ant-table-content > table {
        border-top: 1px solid $gray9;
        border-left: 1px solid $gray9 !important;
      }
    }
  }
  .reportFooter {
    * {
      direction: ltr;
      text-align: left;
    }
    display: none;
    @media print {
      margin-top: auto !important;
      display: block;
      width: 100%;
    }

    padding: 15px 0;
    border-top: 1px solid $black;
    .footerInfo {
      font-size: 11px;
      color: $black;
      &.note {
        color: $blue;
        text-align: right;
        direction: rtl;
      }
    }
  }
  &.print {
    padding: 40px 20px !important;
    background-color: $white !important;
    // height: 11.69in !important;
    min-height: 11.69in;
    width: 8.26in;
    display: flex;
    flex-direction: column;
    position: relative;
    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url("../../assets/images/izoneLogo.png") !important;
      background-repeat: no-repeat;
      background-size: 70%;
      background-position: center center;
    }
    .reportHeader {
      display: flex;
      .headerLogo {
        width: 200px;
      }
    }
    .infoNav {
      display: flex;
    }
    .reportInfos {
      .reportInfo {
        .textarea {
          display: none;
        }
        .vprint {
          display: block;
        }
        // textarea {
        //   border: none !important;
        //   padding: 0;
        //   min-height: auto !important;
        //   background: none !important;
        // }
      }
    }
    .products {
      .ant-table {
        background-color: transparent !important;
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid $gray9;
        border-right: 1px solid $gray9 !important;
      }
      .ant-table-thead > tr > th {
        border-bottom: 1px solid $gray9;
        border-right: 1px solid $gray9 !important;
      }
      .ant-table-content > table {
        border-top: 1px solid $gray9;
        border-left: 1px solid $gray9 !important;
      }
      .ant-table-row {
        background-color: transparent !important;
      }
      .ant-table-cell {
        background-color: transparent !important;
      }
    }
    .reportFooter {
      margin-top: auto !important;
      display: block;
      width: 100%;
    }
  }
}
.formalReport-btn {
  display: flex;
  justify-content: flex-end;
  .print-btn {
    border: none;
    background-color: $blue;
    border-radius: 8px;
    box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
    color: $white;
    padding: 4px 10px;
    margin-right: 20px;
    span {
      margin: 0 5px;
    }
    img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
      margin-left: 7px;
    }
  }
}
