.tickets {
  height: 100%;
  .ticketBtns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-end;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
    .ticketFilters {
      flex: 1 1 auto;
    }
    .ant-switch {
      margin-top: 10px;
      margin-right: 10px;
      @media (max-width: 992px) {
        margin-top: 0;
      }
    }
    .label {
      font-size: 12px;
      margin-bottom: 5px;
      margin-right: 10px;
    }
    .loadingBtn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 10px;
      height: auto;
      .anticon {
        line-height: 0;
        width: 15px;
        height: 15px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .historyBtn {
      color: $blue_2;
      cursor: pointer;
      font-family: "Light";
      display: "flex";
      align-items: "center";
      gap: 3px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
    .dateFilter {
      cursor: pointer;
      background-color: $blue;
      border-radius: 8px;
      border: 1.5px solid $white;
      box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
      color: $white;
      padding: 6px 10px;
      margin-right: 20px;
      font-family: "Light";

      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }

    .changeStatusSelect {
      &.ant-select-single {
        border: none !important;
        box-shadow: none !important;
        // width: 180px;
        margin-left: 10px;
        @media (max-width: 992px) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px;
        }

        .ant-select-selector {
          border: none !important;
          box-shadow: none !important;
          border-radius: 10px;
          padding: 5px 20px;
          height: auto;
          background-color: $blue_2;
          color: $white;

          .ant-select-selection-search {
            color: $white;

            input {
              color: $white;
            }
          }
          .ant-select-selection-item {
            color: $white;
          }
        }
        &.status1 {
          .ant-select-selector {
            background-color: $pending;
          }
        }
        &.status9 {
          .ant-select-selector {
            background-color: $priced;
          }
        }
        &.status10 {
          .ant-select-selector {
            background-color: $paid;
          }
        }
        &.status11 {
          .ant-select-selector {
            background-color: $partial_paid;
          }
        }
        &.status2 {
          .ant-select-selector {
            background-color: $active;
          }
        }
        &.status3 {
          .ant-select-selector {
            background-color: $done;
          }
        }
        &.status4 {
          .ant-select-selector {
            background-color: $approved;
          }
        }
        &.status5 {
          .ant-select-selector {
            background-color: $denied;
          }
        }
      }
    }

    .ant-input-search {
      border-radius: 8px;
      padding: 5px 20px;
      font-size: 16px;
      background-color: $whiteE;
      // margin-right: 10px;
      // width: 240px;
      height: fit-content;
      // margin-top: 22px;
      @media (max-width: 992px) {
        margin-right: 0;
        width: 100%;
        margin-top: 10px;
      }
      .ant-input-group-addon {
        background-color: transparent;
        box-shadow: none !important;
        button {
          background-color: transparent;
          border: none;
          box-shadow: none !important;
          border-radius: 6px 0 0 6px !important;
        }
      }
      .ant-input {
        background-color: transparent;
        border: none;
        box-shadow: none !important;
        font-family: "Medium";
      }
    }
  }
  .altTicketBtns {
    transform: translateY(15px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .historyBtn {
      color: $blue_2;
      cursor: pointer;
      font-family: "Light";
      background-color: $white;
      padding: 5px 20px 0;
      border-radius: 15px 15px 0 0;
      color: $blue;
      display: "flex";
      align-items: "center";
      gap: 3px;
      @media (max-width: 992px) {
        display: block;
        width: fit-content;
        margin-bottom: 10px;
      }
    }
    .actionBtns {
      background-color: white;
      width: fit-content;
      padding: 8px;
      border-radius: 15px;
      display: flex;
      gap: 12px;
    }
  }
  .statusBtn {
    border-radius: 18px;
    padding: 6px 10px;
    color: $white;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    @media (max-width: 992px) {
      padding: 6px 20px;
    }
    &.status1 {
      background-color: $pending;
    }
    &.status9 {
      background-color: $priced;
    }
    &.status10 {
      background-color: $paid;
    }
    &.status11 {
      background-color: $partial_paid;
    }
    &.status2 {
      background-color: $active;
    }
    &.status3 {
      background-color: $done;
    }
    &.status4 {
      background-color: $approved;
    }
    &.status5 {
      background-color: $denied;
    }
  }
  .ticketsTable {
    position: relative;
    .ant-table {
      min-height: 60vh;
      background-color: transparent !important;
      position: relative;
      overflow: hidden;
    }
    .ticketImg {
      border-radius: 8px;
      width: 45px;
      height: 45px;
      background-color: $gray_2;
      display: block;
      margin-inline: auto;
      object-fit: contain;
    }

    .ant-table-row {
      position: relative;
    }
    .ant-table-cell {
      text-align: center;
      background-color: transparent;
      border: none !important;
      position: initial;
      div {
        z-index: 20;
        // position: relative;
      }
      .bgFaded {
        width: 100%;
        height: 100%;
        background-color: rgba($color: $blue_2, $alpha: 0.3);
        border-radius: 12px;
        position: absolute;
        opacity: 0.5;
        top: 0;
        left: 0;
        // animation: 1.5s Faded infinite both;
      }
      // @keyframes Faded {
      //   0% {
      //     background-color: rgba($color: $blue_2, $alpha: 0.3);
      //   }

      //   50% {
      //     background-color: rgba($color: $blue_2, $alpha: 0.1);
      //   }
      //   100% {
      //     background-color: rgba($color: $blue_2, $alpha: 0.3);
      //   }
      // }
      &::before {
        display: none;
      }
    }
    .ant-table-cell {
      @media (max-width: 992px) {
        padding: 0 !important;
      }
    }
    .ant-table-thead {
      @media (max-width: 992px) {
        display: none;
      }
      .ant-table-cell {
        background-color: $grayB !important;
        overflow: hidden !important;
        white-space: nowrap;
        .tickets_count {
          background-color: $white;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: Light;
        .ticketLink {
          margin: 0;
          width: fit-content;
          display: inline-block;
        }
      }
    }
  }
  .seeTicket {
    background-color: $blue_2;
    border-radius: 4px;
    padding: 8px 13px;
    width: fit-content;
    line-height: 1;
    position: relative;
    display: block;
    @media (max-width: 992px) {
      margin: 0 auto;
      color: $white;
      margin-top: 20px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      svg {
        margin-left: 10px;
      }
    }
    .notificationNum {
      width: 18px;
      height: 18px;
      font-size: 10px;
      border-radius: 50%;
      background-color: $orange;
      position: absolute;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      animation: 2s scale infinite both;
      box-shadow: 0px 0px 20px rgba($color: $black, $alpha: 0.05);
    }
    @keyframes scale {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }
      50% {
        transform: translate(-50%, -50%) scale(1.2);
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    @keyframes scale2 {
      0% {
        transform: scale(1);
        // background-color: $visited;
      }
      50% {
        transform: scale(1.2);
        background-color: $green;
      }
      100% {
        transform: scale(1);
        // background-color: $visited;
      }
    }
    svg {
      color: $white;
      font-size: 18px;
      transition: 0.5s;
    }
    &.wave {
      animation: 2s scale2 infinite both;
    }
  }
  .ticketLink:visited {
    .seeTicket {
      background-color: $visited;
    }
  }
  .mobileCard {
    border: 1px solid $whiteE;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: $whiteE;
    .label {
      margin-bottom: 5px;
      font-size: 13px;
      text-align: right;
      span {
        margin-left: 10px;
      }
    }
  }
}
.idLabel {
  background-color: $blue_2;
  color: $white;
  border-radius: 8px;
  padding: 3px 5px;
  // cursor: pointer;
  width: fit-content;
  margin: 0 auto;
}

.ant-picker-dropdown {
  * {
    direction: ltr !important;
  }
  .ant-picker-panel-container {
    .ant-picker-panels {
      .ant-picker-panel {
        .ant-picker-datetime-panel {
          .ant-picker-time-panel {
            display: none;
          }
        }
      }
    }
  }
  .ant-picker-footer {
    // display: none;

    .ant-picker-ok {
      button {
        span {
          font-size: 0;
          &::after {
            content: "Done";
            font-size: 14px;
            color: inherit;
          }
        }
      }
    }
  }
}
.ant-select-item-option {
  .dot {
    width: 9px;
    height: 9px;
    // background-color: $black;
    border-radius: 50%;
    display: inline-block;
    margin: auto 6px;
  }
}

.ant-picker {
  border: none !important;
  background-color: $whiteE;
  border-radius: 8px;
  padding: 10px 20px !important;
  font-size: 16px;
  direction: ltr !important;
  height: fit-content;
  margin-top: 22px;
  * {
    direction: ltr !important;
    font-family: "Medium";
  }
  .ant-picker-clear {
    background: $whiteE !important;
  }
}
