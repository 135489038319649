@font-face {
  font-family: "Bold";
  src: url("assets/fonts/GESSTwoBold.otf");
}
@font-face {
  font-family: "Medium";
  src: url("assets/fonts/GESSTwoMedium.otf");
  unicode-range: U+0600 - U+06FF;
}
@font-face {
  font-family: "Light";
  src: url("assets/fonts/GE_SS_Two_Light.otf");
  unicode-range: U+0600 - U+06FF;
}
@font-face {
  font-family: "Light";
  src: url("assets/fonts/Roboto-Light.ttf");
  unicode-range: U+0600 - U+06FF;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Medium";
  src: url("assets/fonts/Roboto-Medium.ttf");
  unicode-range: U+0600 - U+06FF;
}

* {
  font-family: "Medium";
}
.ant-notification-notice-btn {
  float: left !important;
}

.ant-btn-primary {
  box-shadow: none;
}
