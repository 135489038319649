.technicians {
  .row-label {
    display: block;
    color: $grayC;
  }
  .techniciansBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    .historyBtn {
      color: $blue_2;
      cursor: pointer;
      font-family: "Light";
      display: "flex";
      align-items: "center";
      gap: 3px;
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
    .finishBtn {
      // cursor: pointer;
      border: none;
      background-color: $blue_2;
      border-radius: 8px;
      border: 1.5px solid $white;
      box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.06);
      color: $white;
      padding: 4px 10px;
      margin-right: 20px;
      &.red {
        background-color: $red;
      }
      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin-left: 7px;
      }
    }
  }
  .active-row {
    background-color: rgba(0, 128, 0, 0.05);
  }
  .techniciansCon {
  }
  .techniciansCard {
    padding: 10px;
    border-radius: 20px;
    border: 2px rgba($color: $blue, $alpha: 0.3) solid;
    background-color: rgba($color: $blue, $alpha: 0.2);
    transition: all 250ms ease-in-out;
    cursor: pointer;
    color: $black;
    &:hover {
      background-color: rgba($color: $blue, $alpha: 0.06);
    }
    .techsImg {
      .ant-image {
        overflow: hidden;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $gray_2;
        margin-left: 20px;
        border: 3px $Grey solid;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &.active .ant-image {
        border-color: $green;
      }
    }
    .techsInfo {
      .title {
        color: $Grey;
        font-family: "light";
        font-size: 16px;
      }
      .jobTitle {
        color: rgba($color: $Grey, $alpha: 0.5);
        font-family: "light";
      }
    }
    .workInfo {
      font-family: "Medium";
      & > div:not(:last-child) {
        margin-block-end: 5px;
      }
      .client,
      .date {
        & > div {
          display: inline-block;
        }
        .title {
          margin-inline-end: 5px;
        }
      }
    }
    .ant-rate {
      width: fit-content;
      display: block;
      pointer-events: none;
      @media (max-width: 992px) {
        display: none;
      }
      .ant-rate-star-second {
        // color: $white;
      }
      .ant-rate-star {
        font-size: 26px;
        &:not(:last-child) {
          margin-left: 8px;
          margin-right: 0;
        }

        &.ant-rate-star-zero {
          * {
            color: rgba($color: $Grey, $alpha: 0.3) !important;
          }
        }
        &.ant-rate-star-full {
          color: $blue !important;
        }
      }
    }
  }
  .dark {
    .title {
      color: $blue;
      font-family: "light";
    }
    .bestTechs {
      .carousel {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 400px;
        -webkit-perspective: 500px;
        perspective: 500px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
      }
      .carousel.carousel-slider {
        top: 0;
        left: 0;
      }
      .carousel.carousel-slider .carousel-fixed-item {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 1;
      }
      .carousel.carousel-slider .carousel-fixed-item.with-indicators {
        bottom: 68px;
      }
      .carousel.carousel-slider .carousel-item {
        width: 100%;
        height: 100%;
        min-height: 400px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .carousel.carousel-slider .carousel-item h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }
      .carousel.carousel-slider .carousel-item p {
        font-size: 15px;
      }
      .carousel .carousel-item {
        visibility: hidden;
        width: 200px;
        height: 200px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .carousel .carousel-item > img {
        width: 100%;
      }
      .carousel .indicators {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
      }
      .carousel .indicators .indicator-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 8px;
        width: 8px;
        margin: 24px 4px;
        background-color: rgba(255, 255, 255, 0.5);
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        border-radius: 50%;
      }
      .carousel .indicators .indicator-item.active {
        background-color: $white;
      }
      .carousel.scrolling .carousel-item .materialboxed,
      .carousel .carousel-item:not(.active) .materialboxed {
        pointer-events: none;
      }
      .tap-target-wrapper {
        width: 800px;
        height: 800px;
        position: fixed;
        z-index: 1000;
        visibility: hidden;
        -webkit-transition: visibility 0s 0.3s;
        transition: visibility 0s 0.3s;
      }
      .tap-target-wrapper.open {
        visibility: visible;
        -webkit-transition: visibility 0s;
        transition: visibility 0s;
      }
      .tap-target-wrapper.open .tap-target {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.95;
        -webkit-transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
          -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
        transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
          -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
        transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1),
          opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
        transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1),
          opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
          -webkit-transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
      }
      .tap-target-wrapper.open .tap-target-wave::before {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .tap-target-wrapper.open .tap-target-wave::after {
        visibility: visible;
        -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)
          infinite;
        animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
        -webkit-transition: opacity 0.3s, visibility 0s 1s,
          -webkit-transform 0.3s;
        transition: opacity 0.3s, visibility 0s 1s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s, visibility 0s 1s;
        transition: opacity 0.3s, transform 0.3s, visibility 0s 1s,
          -webkit-transform 0.3s;
      }
      .ant-carousel {
        padding: 20px 0;
        * {
          direction: ltr;
        }
        .Techslide {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          overflow: hidden;
          transition: 0.5s;
          padding: 0 5px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .slick-active {
          .Techslide {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }
      .slick-track {
        .slick-slide {
          opacity: 0;
          &[data-index="1"] {
            opacity: 1;
          }
          &[data-index="0"] {
            opacity: 1;
          }
          &[data-index="-1"] {
            opacity: 1;
          }
        }
      }
      .carousel {
        height: 250px;
        width: 100%;
        .carousel-item {
          width: 150px;
          height: 150px;

          .Techslide {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: 0.5s;
            padding: 0;
            position: relative;
            &::before {
              content: "1";
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 50%);
              width: 40px;
              height: 40px;
              background-color: $gray_2;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              color: $white;
            }
            img {
              border-radius: 50%;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
          &.item1 {
            .Techslide {
              border: 5px solid $gold;
              &::before {
                content: "1";
                background-color: $gold;
              }
            }
          }
          &.item2 {
            .Techslide {
              border: 5px solid $silver;

              &::before {
                content: "2";
                background-color: $silver;
              }
            }
          }
          &.item3 {
            .Techslide {
              border: 5px solid $bronze;

              &::before {
                content: "3";
                background-color: $bronze;
              }
            }
          }
        }
      }
    }
    .techInfo {
      border-radius: 20px;
      background-color: $gray6A;
      padding: 30px 20px;
      .techInfoHeader {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 70%;
          height: 1px;
          background-color: rgba($color: $white, $alpha: 0.22);
        }
        .title {
          color: $white;
          font-family: "light";
          font-size: 16px;
        }
        .seeMore {
          color: $blue;
          font-family: "light";
          font-size: 14px;
        }
      }
      .techStatistic {
        .statisticCard {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          font-size: 16px;
          div {
            color: rgba($color: $white, $alpha: 0.5);
            font-family: "Light";
            &:last-child {
              font-family: "Light";
            }
          }
        }
        .rateCard {
          margin-top: 20px;
          .rateLabel {
            color: rgba($color: $white, $alpha: 0.5);
            font-family: "Light";
            font-size: 16px;
            margin-bottom: 10px;
          }
          .ant-rate {
            width: fit-content;
            display: block;
            margin: 0 auto;
            pointer-events: none;
            .ant-rate-star-second {
              // color: $white;
            }
            .ant-rate-star {
              font-size: 30px;

              &.ant-rate-star-zero {
                * {
                  color: rgba($color: $white, $alpha: 0.5) !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .technicalSlider {
    .slick-arrow {
      display: none !important;
      width: 0px;
      height: 0px;
      overflow: hidden;
      &::before {
        content: "";
      }
    }
  }
}
